.project-link-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2rem;
}

.project-link {
  font-family: 'Montserrat';
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  display: block;
  width: max-content;
  align-items: center;
  color: #c511e5;

  &:hover {
    color: #e049fb;
    opacity: .9;

    svg {
      transform: translateX(10px);
    }

  }

  svg {
    margin-left: 5px;
    transition: transform .3s ease-out;
  }

  @media only screen and (max-width: 425px) {
    font-size: 14px;
    margin-bottom: 2rem;
  }
}

