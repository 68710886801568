.projectNav {
  margin-bottom: 2rem;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0;
    padding: 0 0;

    li {
      display: inline-block;
      width: 14px;
      height: 13px;
      margin: 7px;
      position: relative;

      .slide {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        // text-decoration: none;

        span {
          border-radius: 50%;
          position: absolute;
          z-index: 1;
          height: 4px;
          width: 4px;
          border: 0;
          background-color: #c511e5;
          left: 50%;
          top: 50%;
          margin: -2px 0 0 -2px;

        }

        &:hover {
          span {
            background-color: #e049fb;
            height: 8px;
            width: 8px;
          }
        }

      }

      .active {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-decoration: none;

        span {
          width: 14px;
          height: 14px;
          margin: -6px 0 0 -6px;
          border-radius: 100%;
        }

      }
    }
  }
}
