
#intro {}

.section-header {
  width: 100%;
  height: auto;
  min-height: 700px;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.bg-header {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;
  z-index: -1;
  overflow: hidden;
  background-attachment: fixed;

  @media only screen and (max-width: 320px) {
    background-position: top;
  }
}

#intro .bg-header {
  z-index: 0;
  // background-image: url('../../assets/img/hillmeadeRoad-xsm.jpg');
  background-image: url('../../assets/img/hillmeadeRoad-xsm.jpg');

  @media(min-resolution: 192dpi) and (min-width: 600px) {
    background-image: url('../../assets/img/hillmeadeRoad406x603.jpg');
  }
}




