@import '../../assets/sass/abstracts/mixins';


.link-container {
  position: absolute;
  left: 45%;
  top: 65%;
  font-size: 1.8rem;
  line-height: 2.4rem;
  border: 1px solid #9C27B0;

  width: 10%;
  padding: 8px 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;



  @media only screen and (max-width: 768px) {
    width: 12%;
  };



  @include respond(phone) {
    width: 16%;
    left: 42%;
    top: 68%;
  }

  @media only screen and (max-width: 425px) {
    width: 25%;
  };

  @media only screen and (max-width: 320px) {
    width: 35%;
  };

  @include respond(tab-land) {
    font-size: 1.6rem;
  }

  a {
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #9C27B0;

    svg {
      margin-left: 7px;
      transition: transform .3s ease-out;
      transform: rotate(0deg)
    }

    &:hover {
      color: #ce27eb;
      svg {
        color: #ce27eb;
        transform: rotate(90deg)
      }
    }
  }
}


