@import '../assets/sass/abstracts/mixins';

.skillsView {
  padding: 10rem 0;
  background: rgb(0, 33, 52);

  @include respond(phone) {
    padding: 2rem 0;
  }

}


.skew {
  padding: 20rem 0;
  transform: skewY(-7deg);
  margin-top: -10rem;
  /* select all the direct children to skew in opposite direction */
  & > * {
    transform: skewY(7deg);
  }
}

.skillsSection-wrapper {
  padding: 5rem 12rem;

  @include respond(phone) {
    padding: 5rem 2rem;
  }
}
