

.aboutView {
  background-color: #1b1b1b;
  width: 100%;
  height: auto;
  min-height: 700px;
  padding: 0;
  color: #f2f2f2;
  position: relative;
  overflow: hidden;
}

.section-wrapper {
  padding: 10rem;

  @media only screen and (max-width: 575.98px) {
    padding: 2.5rem;
  }

  @media only screen and (max-width: 425px) {
    padding: 2rem;
  }
}

.about-container {

}


.about-wrapper {
  padding-right: 20px;

  @media only screen and (max-width: 425px) {
    padding-right: 35px;
  }

  @media only screen and (max-width: 320px) {
    // width: 50%;
    padding-right: 0px;
    position: relative;
  }
}

.right-side {
  margin-top: -10rem;
  float: right;
  max-width: 450px;
  padding: 0 0;
  height: auto;
  width: 35%;



  @media only screen and (max-width: 425px) {
    margin-top: -22rem;
    width: 40%;
  }

  @media only screen and (max-width: 320px) {
    width: 50%;
    // position: absolute;
  }

}

.headshot {
  display: inline-block;
  width: 85%;
  height: auto;
  border-radius: 50%;
  max-width: 420px;
}

.about-text-wrapper {
  display: inline-block;
  width: 60%;
  vertical-align: top;
}

.about-text {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: 100;
  font-family: 'Montserrat', sans-serif;

}

.about-music-wrapper {

  width: 90%;
  margin: 2rem 0;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 425px) {
    margin: 0 0 2rem 0;
  }

}
