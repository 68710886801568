@import './assets/sass/abstracts/mixins';

@font-face {
  font-family: 'source_sans_pro';
  src: url('./assets/fonts/source-sans-pro/sourcesanspro-it-webfont.woff2') format('woff2'),
    url('./assets/fonts/source-sans-pro/sourcesanspro-it-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* // This defines what 1rem is */
  /* //1 rem = 10px; 10px/16px = 62.5% */
  font-size: 62.5%;


  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(big-desktop) {
    font-size: 80%;
  }
}

body, html {
  box-sizing: border-box;
}

body, html, .App {
  margin: 0 0;
  padding: 0 0;
  background: #1d1d20;
  font-family: 'Chronicle Display', sans-serif;

  height: 100% !important;
}


#app-body {
  height: 100%;
  overflow: hidden;
  position: relative;
}
