@import '../assets/sass/abstracts/mixins';

.laptop-bg {
  // background-image: url('../assets/img/macbook-47638-xsm.png');
  background-image: url('../assets/img/macbook-47638.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // @media(min-resolution: 192dpi) and (min-width: 400px) {
  //   background-image: url('../assets/img/macbook-47638.png');
  // }
}

.image-bg {
  display: block;
  height: 71%;
  width: 60.5%;
  z-index: 10000;
  top: 49%;
  left: 49%;
  position: absolute;
  transform: translate(-50%, -50%);

  // @include respond(big-desktop) {
  //   height: 71%;
  //   width: 60.5%;
  // }

  @media only screen and (max-width: 991.98px) {
    width: 68%;
    height: 72%;
  }

  // @include respond(tab-port) {
  //   width: 67%;
  //   height: 71%;
  // }

  @media only screen and (max-width: 767.98px) {
    width: 70%;
    height: 68%;
  }

  // @include respond(phone) {
  //   width: 70%;
  //   height: 68%;
  // }

  @media only screen and (max-width: 575.98px) {
    width: 70%;
    height: 64%;
  }

  @media only screen and (max-width: 320px) {
    width: 70%;
    height: 47%;
  }
}

.app-image {
  height: 90%;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
