@import '../../assets/sass/abstracts/mixins';

#side-nav {
  position: fixed;
  right: 34px;
  z-index: 1;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

#side-nav.active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s,opacity .5s;

}


.side-nav-links {
  list-style: none;
  padding: 0;

  li {
    text-align: right;
  }
}

.dot-link {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  text-decoration: none;


  &::after {
    clear: both;
    content: "";
    display: table;

  }
}


// to show the label on hover
#side-nav .dot-link:focus .dot-label,
#side-nav .dot-link:hover .dot-label {
  opacity: 1;
}

#side-nav .dot-link:focus span,
#side-nav .dot-link:hover span {
  transform: scale(.75);
}

#side-nav .dot-link span {
  float: right;
  display: inline-block;
  transform: scale(.6);
}

.dot {
    position: relative;
    top: 7px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: rgba(255,255,255,.25);
    transition: transform .2s,background-color .5s;
    transform-origin: 50% 50%;
  }


.dot-label {
  position: relative;
  top: 3px;
  padding: 0.4em 0.5em;
  color: #f2f2f2;
  font-size: 1.25em;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0;
  transition: transform .2s,opacity .2s;
  transform-origin: 100% 50%;

  @include respond(phone) {
    font-size: 1em;
    padding: 0.8em 0.25em;
  }
}



.nav-link.active .dot {
  background: #00b7c7;
}
