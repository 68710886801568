@import '../assets/sass/abstracts/mixins';

.projectView {
  background: #1d1d20;
  padding: 10rem 0rem;
  margin-bottom: 19rem;

  @media only screen and (max-width: 425px) {
    padding: 4rem 2rem;
  }
}

.skew {
  padding: 35rem 0;
  margin-top: -12vh; // to remove white space
}

.projects-wrapper {
  padding: .5rem 12rem;

  // less than 900px
  @include respond(tab-port) {
    padding: .5rem 8rem;
  }


  @media(max-width: 768px) {
    padding: .5rem 6rem;
  }

  // less than 600px
  @include respond(phone) {
    padding: .5rem 4rem;
  }


  @media(max-width: 576px) {
    padding: .5rem 4rem;
  }

  @media only screen and (max-width: 425px) {
    padding: 0 0;
    margin-top: 5rem;
  }
}
