@import '../../assets/sass/abstracts/mixins';

.project-visual {
  width: 100%;
  margin-top: 1rem;
  height: 56rem;
  position: relative;

  &__icon-box {
    width: 5%;
    height: 10%;
    position: absolute;
    top: 40%;

    &--left {

      left: 8%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.6rem;
      color: gray;
      z-index: 20000;

      @include respond(phone) {
        left: 6%;
        font-size: 1.8rem;
      }

      &:hover {
        transform: translateY(-2px);
        color: rgb(81, 81, 81);
      }
    }

    &--right {

      right: 8%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.6rem;
      color: gray;
      z-index: 20000;

      @include respond(phone) {
        right: 6%;
        font-size: 1.8rem;
      }

      &:hover {
        transform: translateY(-2px);
        color: rgb(81, 81, 81);
      }
    }
  }

  @include respond(tab-land) {
    height: 46rem;
  }


  @include respond(phone) {
    height: 36rem;
  }

  @media only screen and (max-width: 425px) {
    height: 30rem;
    margin-top: 0;
  }
}




