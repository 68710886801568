.skill {
  padding: 2px 0;
  width: 100%;

  @media only screen and (max-width: 425px) {
    width: 85%;
  }

  &__heading {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 700;
    color: #8896a4;
    margin-bottom: 5px;
  }

  &__icon {
    font-size: 1em;
    margin-right: 5px;
  }


}

.bar {
  height: 28px;
  background: rgb(238, 238, 238);
  color: rgb(102, 102, 102);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 5px;
  text-align: center;
}

.fillBar {
  align-items: flex-end;
  background-color: rgb(0, 161, 167);
  display: block;
  opacity: 1;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 1.1s ease-in-out 0.2s;
}


.tag {
  align-items: center;
  background-color: rgb(4, 194, 201);
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;


}

.unfilledBar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  height: 100%;
  width: 30px;
  z-index: 1;
}
