
.contactView {
  margin-top: -30vh;
  padding-bottom: 4rem;
  background-image: url('../assets/img/robertMondavi-xsm.jpg');
  position: relative;
  min-height: 700px;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media only screen and (max-width: 320px) {
    margin-top: -34vh;
    background-position: top;
  }


  @media(min-resolution: 192dpi) and (min-width: 600px) {
    background-image: url('../assets/img/robertMondavi604x599.jpg');
  }
}
