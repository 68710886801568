
.revealHeading-container {
  width: 100%;
  height: 101vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}


.revealHeading-wrapper {
  text-align: center;
  padding: 0 5%;
  width: 100%;
  position: relative;
  top: -5%;
}

.inner-text,
.revealHeading,
.name,
.subheading {
  opacity: 0;
  animation-duration: .8s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
}


.revealHeading {
  display: block;
  font-size: 6rem;
  letter-spacing: 5px;
  font-weight: 900;
  position: relative;
  padding-bottom: 10px;
  color: transparent;
  overflow: hidden;
  opacity: 1;


  @media(max-width: 576px) {
    font-size: 5rem;
  }




  &::after {
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // color: #f2f2f2;
    color: #F4A949;
    text-shadow:
    1px 1px 2px #000,
    4px 4px 4px #FFF,
    8px 8px 8px #000;
    // text-shadow:
    //   4px 4px 4px #FFF,
    //   8px 8px 8px #000;
    animation-name: reveal-up;
    animation-fill-mode: backwards;
    animation-duration: .4s;
    animation-delay: .7s;
  }
}

.name {
  position: relative;
  display: inline-block;
  opacity: 1;

  //  the middle bar
  &::before {
    content: '';
    position: absolute;
    top: calc(100% + 8px);
    left: -1em;
    height: 2px;
    width: calc(100% + 2em);
    background-color: #f2f2f2;
    animation: loading-mask 1s .3s both;

  }
}

.revealHeading .name::before {
  background-color: #000;
}

.subheading-wrapper {
  overflow: hidden;
}

.subheading {
  position: relative;
  margin: 0;
  padding: 0;
  animation-name: reveal-down;
  animation-duration: .4s;
  animation-delay: .7s;
}

.inner-text {
  display: inline-block;
  text-decoration: none;
  line-height: 1.5;
  // color: #f2f2f2;
  color: #F4A949;
  text-transform: capitalize;

  font-family: 'Montserrat';
  opacity: 1;
  margin: 0;

  letter-spacing: 2px;
  font-size: 1.6rem;
  background-color: rgba(black, .7);
  padding: 1.2rem 1rem;
  font-weight: 300;

  @media(max-width: 576px) {
    font-size: 1.4rem;
    letter-spacing: 1.2px;
  }
}


@keyframes reveal-up {
  0% {
      opacity: 1;
      transform: translateY(100%)
  }

  100% {
      opacity: 1;
      transform: translateY(0)
  }
}

@keyframes reveal-down {
  0% {
      opacity: 1;
      transform: translateY(-100%)
  }

  100% {
      opacity: 1;
      transform: translateY(0)
  }
}

@keyframes loading-mask {
  0%,100% {
      transform: scaleX(0)
  }

  40%,60% {
      transform: scaleX(1)
  }
}
