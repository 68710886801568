// COLORS
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;


// FONT
$default-font-size: 1.6rem;


// GRID
$grid-width: 114rem;
// $grid-width: 140rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
$gutter-horizontal-small: 4rem;


//box-shadow values
$box-shadow-primary: 0 1.5rem 4rem rgba($color-black, .15);