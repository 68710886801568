@import '../../assets/sass/abstracts/mixins';


.audio-player {
  // background: inherit;

  width: 1fr;
  margin-left: 2rem;

  @include respond(phone) {
    flex: 0 0 50%;
  }
}


.inner {
  margin: 0 auto;
}

/* audio info */
.audio-info {
  display: flex;
}

/* audio image */
.icon-wrapper {

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.audio-icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #989898;
}

/* controls */
.controls {
  background-color: inherit;
  color: #fff;
  padding: 2px 0;
}

.controls button {
  color: #fff;
  border: none;
  background-color: transparent;
  margin-right: 8px;
  cursor: pointer;
}

.controls:hover button:hover {
  color: grey;
}


button svg {
  font-size: 20px;
}


/* =========================
Custom Input range
=============================*/


/* Input range - chrome and safari */
/* give the input and range track relative positioning
*/
input[type="range"] {
  --range-progress: 0;

  -webkit-appearance: none;
  position: relative;
  background:#ccc;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* Input range - firefox */
input[type="range"]::-moz-range-track {
  position: relative;
  background:#ccc;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* played progress bar up to ball length - Chrome & safari*/
input[type="range"]::before {
  content: '';
  height: 2px;
  background: #f50;
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

/* played progress length - firefox */
input[type="range"]::-moz-range-progress {
  background: #f50;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2px;
}

/* slider thumb - chrome and safari */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none;
  background-color: #f50;
  cursor: pointer;
  position: relative;
}

/* dragging thumb - chrome and safari */
input[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

/* slider thumb - firefox */
input[type="range"]::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #f50;
  cursor: pointer;
  border: transparent;
  position: relative;
}
/* dragging thumb - firefox */
input[type="range"]:active::-moz-range-thumb {
  transform: scale(1.2);
}
